import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import sty from "./Map.module.scss";

const Map = ({ children }) => {
  return (
    <div className={sty.Map}>
      Map!
      {children}
    </div>
  );
};

Map.propTypes = {};

export default Map;
